import { AfterViewChecked, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'ashcorp-field-currency',
  templateUrl: './ashcorp-field-currency.component.html',
  animations: fuseAnimations
})
export class ashcorp_field_currency_component implements AfterViewChecked {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() show = true;
  @Input() readonly = false;

  value = "0.00";
  initialized: boolean = false;
  constructor() { }

  ngAfterViewChecked(): void {
    if (!this.initialized) {
      this.value = this.form.getRawValue()[this.name]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? "0.00";
      this.initialized = this.value != "0.00" && this.value != "";
    }
  }

  focus(event: KeyboardEvent): void {
    const element = event.target as HTMLInputElement;
    element.setSelectionRange(this.value.length, this.value.length);
  }

  keydown(event: KeyboardEvent): void {
    const keys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
    const key = event.key;
    if (!/^\d$/.test(key) && !keys.includes(key)) {
      event.preventDefault();
    }
  }

  refresh_value(event: KeyboardEvent): void {
    const element = event.target as HTMLInputElement;
    let value = element.value;
    if (value.length > 4) {
      let num_value = Number(value.toString().replace(/,/g, '').replace('.', '')) / 100;
      element.value = num_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
      value = "0000" + value.replace(".", "");
      this.value = value[value.length - 3] + "." + value.slice(-2);
    }
    element.setSelectionRange(element.value.length, element.value.length);
  }

  blur(): void {
    this.form.get(this.name).patchValue(parseFloat(this.value.replace(/,/g, '')));
  }
}
