<div [formGroup]="form">
    <mat-form-field style="width:100%;">
        <mat-label *ngIf="show">{{ label }}</mat-label>
        <input matInput
               [formControlName]="name"
               style="display: none;"
               #input />

        <input
               matInput
               type="text"
               style="text-align:right;"
               [placeholder]="label"
               [readonly]="readonly"
               (click)="focus($event);"
               (focus)="refresh_value($event)"
               (keydown)="keydown($event);"
               (keyup)="refresh_value($event);"
               (blur)="blur();"
               [value]="value" />

        <mat-error *ngIf="form?.get(name)?.hasError('required')">
            {{ label }} is <strong>required</strong>
        </mat-error>
    </mat-form-field>
</div>

<!-- (keydown)="keydown($event)"
               (focus)="focus($event)"
               (blur)="blur($event, form?.get(name))"
               [value]="value()"  -->