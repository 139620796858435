import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ashcorp_table_mini_data } from "../ashcorp-table.types";
import { MatTableDataSource } from "@angular/material/table";
import { shared_service } from "app/shared/shared.service";

@Component(
    {
        selector: 'ashcorp-table-mini',
        templateUrl: './ashcorp.table.mini.component.html',
        changeDetection: ChangeDetectionStrategy.Default
    }
)

export class ashcorp_table_mini_component implements OnChanges {
    @Input() table: ashcorp_table_mini_data;

    title: string = "";
    icon: string = null;
    headers: object = {};
    data_display: MatTableDataSource<any> = new MatTableDataSource();
    get header(): Array<string> {
        return Object.keys(this.headers);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.table && changes.table.currentValue) {
            if (this.table) {
                this.title = this.table.title;
                this.icon = this.table.icon;
                this.headers = this.table.headers;
                this.data_display.data = this.table.data;
            }
        }
    }

    constructor(public _shared_service: shared_service) { }

    format(header: any, value: any): string {
        if (value == null) {
            return null;
        }
        switch (header.format) {
            case "two_dp":
                return this._shared_service.two_dp(value);
        }
        return value;
    }

    tracker(index: number, item: any): any {
        return item?.id || item?.Id || index;
    }
}