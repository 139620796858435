<div [formGroup]="form">
    <mat-form-field style="width:100%;">
        <mat-label *ngIf="show" [innerHTML]="label"></mat-label>
        <mat-select [formControlName]="name">
            <mat-option *ngFor="let option of selections ?? [];" [value]="option?.value" [innerHTML]="option?.label"></mat-option>
        </mat-select>
        <mat-error *ngIf="show && form?.get(name)?.hasError('required')">
            {{label}} is <strong>required</strong>
        </mat-error>
    </mat-form-field>
</div>