<div class="col-span-1 flex flex-col items-center">
    <div class="p-3 border rounded">
        <ng-container *ngIf="images.length; else noImage">
            <img class="w-30 min-w-30" [src]="images[image_idx]">
        </ng-container>
        <ng-template #noImage>
            <span class="flex items-center min-h-20 text-lg font-semibold">NO IMAGE</span>
        </ng-template>
    </div>
    <div class="flex items-center mt-2" *ngIf="images.length">
        <button mat-icon-button (click)="cycleImages(false)">
            <mat-icon class="icon-size-5" svgIcon="heroicons_solid:arrow-narrow-left"></mat-icon>
        </button>
        <span class="font-sm mx-2">
            {{image_idx + 1}} of {{images.length}}
        </span>
        <button mat-icon-button (click)="cycleImages(true)">
            <mat-icon class="icon-size-5" svgIcon="heroicons_solid:arrow-narrow-right"></mat-icon>
        </button>
    </div>
</div>