import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { shared_service } from 'app/shared/shared.service';
import { ashcorp_field_file_type } from './ashcorp-field-file.types';

@Component({
  selector: 'ashcorp-field-file',
  templateUrl: './ashcorp-field-file.component.html',
  animations: fuseAnimations
})

export class ashcorp_field_file_component implements OnChanges {
  @Input() label: string;
  @Input() accept?: string = "*.*";
  @Input() multiple?: boolean = false;
  @Input() show?: boolean = true;
  @Input() readonly?: boolean = false;

  public helper: shared_service;

  public form: FormGroup;

  constructor(
    private _shared_service: shared_service,
    public _form_builder: FormBuilder
  ) {
    this.helper = this._shared_service;

    this.form = _form_builder.group(
      {
        File: new FormControl("", [Validators.required])
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label && changes.label.currentValue) {
      this._shared_service.uploaded_files$(changes.label.currentValue)
        .subscribe(
          (files: any) => {
            this.form.setErrors(null);
            if (files) {
              if (files.status == "error") {
                this.form.patchValue({ File: files.message });
                this.form.setErrors({ "api": files.message });
                return;
              }
              let fname = [];
              files.files.forEach((file: ashcorp_field_file_type) => {
                fname.push(file.original);
              });
              this.form.patchValue({ File: fname.join(", ") });
            }
          });
    }
  }

  trigger_upload(event: any) {
    const button = document.getElementById(this.helper.uploaded_files_key(this.label));
    if (button) {
      button.click();
    }
  }

  file_change(event: any) {
    if (event.target.files.length > 0) {
      this.helper.upload_files(this.label, event.target.files, this.accept);
    }
  }
}
