export class ashcorp_field_files_type {
    files: Array<ashcorp_field_file_type>;
    message: string;
    status: string;

    constructor(files: any, status: string = "success", message: string = null) {
        this.files = files;
        this.status = status;
        this.message = message;
    }
}

export class ashcorp_field_file_type {
    original: string;
    tmp: string;

    constructor(original: string, tmp: string) {
        this.original = original;
        this.tmp = tmp;
    }
}