import { ashcorp_table_action, ashcorp_table_filter_grouped } from "app/components/ashcorp-table/ashcorp-table.types";

export class crumb {
    name: string;
    url: string;
}

export class breadcrumb {
    title: string;
    crumbs: Array<crumb>;
    search: boolean = false;
    company: boolean = false;
    branch: boolean = true;
    filters?: ashcorp_table_filter_grouped[] = [];
}

export interface breadcrumb_button {
    name: string;
    url: string;
    icon: string;
}