<div class="items-center justify-between w-full">
    <div class="xl:col-span-1 flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden">
        <div class="grid grid-cols-2 gap-4 pt-6 px-4" *ngIf="title">
            <div class="text-lg font-medium tracking-tight leading-6 truncate" style="line-height: 40px !important;display: flex;">
                <mat-icon *ngIf="icon" class="icon-size-4 mr-3" [svgIcon]="icon"></mat-icon>
                <div class="mr-3">{{title}}</div>
            </div>
        </div>
        <div class="overflow-x-auto">
            <table class="w-full bg-transparent" mat-table matSort [dataSource]="data_display" [trackBy]="tracker" #viewTable multiTemplateDataRows>
                <ng-container *ngFor="let header of header" [matColumnDef]="header">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef [ngClass]="headers[header].class ?? ''"
                        [innerHTML]="headers[header].label ?? header" style="cursor: pointer;height:40px;"> </th>
                    <td mat-cell *matCellDef="let row; let i = dataIndex;">
                        <div class="mr-2 font-medium text-sm whitespace-nowrap"
                             [ngClass]="(headers[header]?.class ?? '') + ' ' + (row?.class ?? '')"
                             [innerHTML]="headers[header].format ? format(headers[header], row[header]) : row[header]">
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="header"></tr>
                <tr mat-row *matRowDef="let row; columns: header;" style="height:40px;"></tr>
            </table>
        </div>
    </div>
</div>