<div [formGroup]="form">
    <mat-form-field style="width:100%;">
        <mat-label *ngIf="show">{{label}}</mat-label>
        <input matInput [formControlName]="name" [placeholder]="label" [readonly]="readonly" type="number" style="text-align:right;" #input>
        <mat-error *ngIf="form?.get(name)?.hasError('required')">
            {{label}} is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form?.get(name)?.hasError('min')">
            {{label}} should be <strong>more</strong> than <strong>{{form.get(name)?.errors?.['min']?.min}}</strong>
        </mat-error>
        <mat-error *ngIf="form?.get(name)?.hasError('max')">
            {{label}} should be <strong>less</strong> than <strong>{{form.get(name)?.errors?.['max']?.max}}</strong>
        </mat-error>
        <mat-error *ngIf="form?.get(name)?.hasError('value')">
            {{ form.get(name)?.getError('value') }}
        </mat-error>
    </mat-form-field>
</div>