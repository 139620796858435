<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="false">
        <ng-container fuseVerticalNavigationContentHeader> </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="dark:hidden w-20" [src]="cdn_url + 'images/logo.png'">
                    <img class="hidden dark:flex w-20" [src]="cdn_url + 'images/logo.png'">
                </div>
                <img class="flex lg:hidden w-8" [src]="cdn_url + 'images/logo.png'">
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon svgIcon="heroicons_outline:menu"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user></user>
        </div>
    </div>

    <!-- Breadcrumbs -->
    <div class="grid grid-cols-4 gap-6 p-6 border-b bg-card dark:bg-transparent m-0" *ngIf="company!=undefined && breadcrumb!=undefined">
        <div class="col-span-2">
            <div class="flex flex-wrap items-center font-medium">
                <div *ngIf="breadcrumb.company">
                    <a class="capitalize whitespace-nowrap text-primary-500">{{company.title}}</a>
                </div>
                <div *ngIf="breadcrumb.branch">
                    <a class="capitalize whitespace-nowrap text-primary-500">{{branch.title}}</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap" *ngFor="let crumb of breadcrumb.crumbs;">
                    <mat-icon class="icon-size-5 text-secondary" svgIcon="heroicons_solid:chevron-right"></mat-icon>
                    <a class="capitalize ml-1 text-primary-500" [href]="crumb.url">{{crumb.name}}</a>
                </div>
            </div>
            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    {{breadcrumb.title}}
                </h2>
            </div>
        </div>
        <div class="col-span-2">
            <div *ngIf="actions" class="pl-4" style="float:right;">
                <ng-container *ngFor="let action of actions">
                    <button matripple class="mx-1 mat-stroked-button" [matTooltip]="action.label" (click)="action.click(action)" [ngClass]="action.class">
                        <mat-icon class="icon-size-4" *ngIf="action.icon" [svgIcon]="action.icon"></mat-icon>
                        <ng-container *ngIf="!action.icon">{{action.label}}</ng-container>
                    </button>
                </ng-container>
            </div>

            <div *ngIf="breadcrumb.search" class="pl-4" style="float:right;">
                <mat-form-field style="width: 300px !important;" class="mat-form-field fuse-mat-dense mat-primary mat-form-field-appearance-fill">
                    <div class="mat-form-field-wrapper">
                        <div class="mat-form-field-flex">
                            <div class="mat-form-field-prefix">
                                <mat-icon matPrefix svgIcon="heroicons_outline:search"></mat-icon>
                            </div>
                            <div class="mat-form-field-infix">
                                <input matInput (keyup)="apply_filter($event)" placeholder="Search" #input>
                            </div>
                        </div>
                    </div>
                </mat-form-field>
            </div>

            <div *ngIf="breadcrumb.filters" class="pl-4" style="float:right;">
                <ng-container *ngFor="let filter of breadcrumb.filters">
                    <button matripple class="mx-1" [matTooltip]="filter.label" (click)="apply_filter_button(filter)" [ngClass]="(filter.active ? 'mat-primary  mat-stroked-button' : ' mat-stroked-button')">
                        <mat-icon class="icon-size-4" *ngIf="filter.icon" [svgIcon]="filter.icon"></mat-icon>
                        <ng-container *ngIf="!filter.icon">{{filter.label}}</ng-container>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- 
    <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Ashcorp Addams Ltd &copy; {{currentYear}}</span>
    </div> 
    -->

</div>