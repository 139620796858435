import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { shared_service } from 'app/shared/shared.service';
import { ashcorp_field_select_type } from './ashcorp-field-select.types';

@Component({
  selector: 'ashcorp-field-select',
  templateUrl: './ashcorp-field-select.component.html'
})
export class ashcorp_field_select_component implements OnChanges {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() options: ashcorp_field_select_type[] = null;
  @Input() observable: Observable<ashcorp_field_select_type[]> = null;
  @Input() show = true;

  public selections: ashcorp_field_select_type[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.selections = this.options;
    }

    if (changes.observable && changes.observable.currentValue) {
      this.observable.subscribe(
        (options: ashcorp_field_select_type[]) => {
          this.selections = this.options;
        }
      );
    }
  }
}
