import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { shared_service } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-field-seperator',
  templateUrl: './ashcorp-field-seperator.component.html',
  animations: fuseAnimations
})

export class ashcorp_field_seperator_component {
  @Input() class?: any;
  @Input() style?: any = "height: 5px;margin-top: -50px;";

  public helper: shared_service;
  public break_line: number[] = [];

  constructor(private _shared_service: shared_service) {
    this.helper = this._shared_service;
  }
}
