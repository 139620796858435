import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'ashcorp-button',
  templateUrl: './ashcorp-button.component.html',
  animations: fuseAnimations
})

export class ashcorp_button_component {
  @Output() click = new EventEmitter<void>();
  @Input() color: string;

  constructor() { }

  trigger(event: Event) {
    event.stopPropagation();
    this.click.emit();
  }
}
