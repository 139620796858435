import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'ashcorp-carousel-thumbnail',
  templateUrl: './ashcorp-carousel-thumbnail.component.html',
  animations: fuseAnimations
})

export class ashcorp_carousel_thumbnail_component {
  @Output() click = new EventEmitter<void>();
  @Input() color: string;

  constructor() { }

  images: Array<string> = [
    "https://demo.ashcorp.xyz/assets/images/apps/ecommerce/products/watch-10-01.jpg",
    "https://demo.ashcorp.xyz/assets/images/apps/ecommerce/products/watch-10-02.jpg",
    "https://demo.ashcorp.xyz/assets/images/apps/ecommerce/products/watch-10-03.jpg"
  ];
  image_idx: number = 0;

  cycleImages(forward: boolean = true): void {
    const count = this.images.length;
    const currentIndex = this.image_idx;

    const nextIndex = currentIndex + 1 === count ? 0 : currentIndex + 1;
    const prevIndex = currentIndex - 1 < 0 ? count - 1 : currentIndex - 1;

    if (forward) {
      this.image_idx = nextIndex;
    }
    else {
      this.image_idx = prevIndex;
    }
  }

  trigger(event: Event) {
    event.stopPropagation();
    this.click.emit();
  }
}
