<div [formGroup]="form">
    <mat-form-field style="width:100%;">
        <mat-label *ngIf="show">{{label}}</mat-label>
        <input matInput formControlName="File" [placeholder]="label" readonly="readonly" #input>
        <input [id]="helper.uploaded_files_key(this.label)" type="file" [accept]="accept" [multiple]="multiple" style="display:none;" (change)="file_change($event)">
        <button mat-icon-button="" type="button" class="mat-focus-indicator mat-icon-button mat-button-base" (click)="trigger_upload($event);">
            <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:upload"></mat-icon>
        </button>
        <mat-error *ngIf="form?.get('File')?.hasError('required')">
            {{label}} is <strong>required</strong>
        </mat-error>
    </mat-form-field>
</div>