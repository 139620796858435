import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { pagination_type, table_header_type } from 'app/shared/shared.types';
import { ActivatedRoute } from '@angular/router';
import { navigation_service } from 'app/core/navigation/navigation.service';
import { ashcorp_table_action, ashcorp_table_filter_grouped } from 'app/components/ashcorp-table/ashcorp-table.types';

@Component({
    selector: 'ashcorp-table',
    templateUrl: './ashcorp.table.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class ashcorp_table_component implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;

    @Input() source: Observable<any[]>;
    @Input() service: any;
    @Input() type: any;
    @Input() headers: Record<string, table_header_type>;
    @Output() select = new EventEmitter<any>();

    private _close: Subject<any> = new Subject<any>();

    data_source$: Observable<any[]> = null;
    service$: any = null;
    type$: any = null;
    headers$: Record<string, table_header_type>;

    item_count: number = 0;
    item_selected: any | null = null;
    loader: boolean = false;
    init: boolean = false;

    header: string[] = [];
    columns: string[] = [];
    pagination: pagination_type;
    search: string = "";
    filters: ashcorp_table_filter_grouped[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.service && changes.service.currentValue) {
            if (this.service) {
                this.service$ = this.service;
            }
        }
        if (changes.source && changes.source.currentValue) {
            if (this.source) {
                this.data_source$ = this.source;
            }
        }
        if (changes.type && changes.type.currentValue) {
            if (this.type) {
                this.type$ = this.type;
            }
        }
        if (changes.headers && changes.headers.currentValue) {
            if (this.headers) {
                this.header = this.keys(this.headers);
                this.columns = this.keys(this.headers);
                this.columns.push("details");
                this.headers$ = this.headers;
            }
        }
    }

    get names(): string { return this.type$._names; }
    get name(): string { return this.type$._name; }

    keys(object: any): string[] {
        return Object.keys(object);
    }

    constructor(
        protected _route: ActivatedRoute,
        protected _navigation_service: navigation_service,
        private _changed: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.service$.pagination$.pipe(takeUntil(this._close)).subscribe((pagination: pagination_type) => { this.pagination = pagination; this._changed.markForCheck(); });

        this.data_source$.pipe(takeUntil(this._close))
            .subscribe((items: any[]) => {
                this.item_count = items.length;
                if (this.item_count == 0 && this.pagination.lastPage > 0) {
                    this.service$.get_list(
                        this.pagination.page - 1,
                        this.pagination.size,
                        this.pagination.sort,
                        this.pagination.direction,
                        this.search,
                        this.filters
                    ).subscribe();
                }
                this._changed.markForCheck();
            });

        this._navigation_service.search$
            .pipe(
                takeUntil(this._close),
                debounceTime(300),
                switchMap((search) => {
                    this.close_details();
                    this.loader = true;
                    this.search = search;
                    return this.service$.get_list(
                        0,
                        this.pagination.size,
                        this.pagination.sort,
                        this.pagination.direction,
                        this.search,
                        this.filters
                    );
                }),
                map(() => {
                    this.loader = false;
                })
            )
            .subscribe();

        this._navigation_service.filters$
            .pipe(
                takeUntil(this._close),
                debounceTime(300),
                switchMap((filters) => {
                    this.close_details();
                    this.loader = true;
                    this.filters = filters.map((f: ashcorp_table_filter_grouped) => f.value);
                    return this.service$.get_list(
                        0,
                        this.pagination.size,
                        this.pagination.sort,
                        this.pagination.direction,
                        this.search,
                        this.filters
                    );
                }),
                map(() => {
                    this.loader = false;
                })
            )
            .subscribe();

        this.init = true;

        this._navigation_service.actions(
            [
                {
                    // icon: "heroicons_solid:user-add",
                    label: "Add new " + this.name,
                    access: 99999,
                    click: this.create,
                    context: this
                }
            ]
        );
    }

    ngAfterViewInit(): void {
        this._sort.sortChange
            .pipe(takeUntil(this._close))
            .subscribe(() => {
                this._paginator.pageIndex = 0;
                this.close_details();
            });

        merge(this._sort.sortChange, this._paginator.page).pipe(
            switchMap(() => {
                this.close_details();
                this.loader = true;
                return this.service$.get_list(
                    this._paginator.pageIndex,
                    this._paginator.pageSize,
                    this._sort.active,
                    this._sort.direction,
                    this.search,
                    this.filters
                );
            }),
            map(() => {
                this.loader = false;
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._close.next(null);
        this._close.complete();
    }

    track_by(index: number, item: any): any {
        return item.id || index;
    }

    toggle_details(id: number): void {
        if (this.item_selected) {
            const similar = this.item_selected.id === id;
            this.close_details();
            if (similar) {
                return;
            }
            setTimeout(() => {
                this.service$
                    .get_by_id(id)
                    .subscribe(
                        (item: any) => {
                            this.item_selected = item;
                            this.select.emit(item);
                            this._changed.markForCheck();
                        }
                    );
            }, 300);
        }
        else {
            this.service$
                .get_by_id(id)
                .subscribe(
                    (item: any) => {
                        this.item_selected = item;
                        this.select.emit(item);
                        this._changed.markForCheck();
                    }
                );
        }
    }

    close_details(): void {
        this.item_selected = null;
        this.select.emit(null);
    }

    create(button: ashcorp_table_action): void {
        const self = button.context;
        self.service
            .create()
            .subscribe(
                (new_item: any) => {
                    //TODO:: OPEN ON CREATE
                    // setTimeout(
                    //     () => {
                    //         // self.item_selected = new_item;
                    //         self._changed.markForCheck();
                    //     }, 2000
                    // )
                    // self.form.patchValue(new_item);
                }
            );
    }
}
